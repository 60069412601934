import { render, staticRenderFns } from "./star-rating.vue?vue&type=template&id=36415231&scoped=true"
import script from "./star-rating.vue?vue&type=script&lang=js"
export * from "./star-rating.vue?vue&type=script&lang=js"
import style0 from "./star-rating.vue?vue&type=style&index=0&id=36415231&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36415231",
  null
  
)

export default component.exports