import { render, staticRenderFns } from "./review-header.vue?vue&type=template&id=18bb7423&scoped=true"
import script from "./review-header.vue?vue&type=script&lang=js"
export * from "./review-header.vue?vue&type=script&lang=js"
import style0 from "./review-header.vue?vue&type=style&index=0&id=18bb7423&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18bb7423",
  null
  
)

export default component.exports