import { render, staticRenderFns } from "./pagination.vue?vue&type=template&id=e9a5a9d4&scoped=true"
import script from "./pagination.vue?vue&type=script&lang=js"
export * from "./pagination.vue?vue&type=script&lang=js"
import style0 from "./pagination.vue?vue&type=style&index=0&id=e9a5a9d4&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e9a5a9d4",
  null
  
)

export default component.exports